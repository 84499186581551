import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Profile',
    icon: 'message-circle-outline',
    home: true,
    children: [
      {
        title: 'updateProfile',
        link: '/pages/extra-components/updateprofile',
      },

      {
        title: 'Profile Information',
        link: '/pages/extra-components/calendar',
      },
      {
        title: 'ChangePassword',
        link: '/pages/extra-components/progress-bar',
      },

    ],
  },

  /* {
    title: 'IoT Dashboard',
    icon: 'home-outline',
    link: '/pages/iot-dashboard',
  }, */
  {
    title: 'FEATURES',
    group: true,
  },
  {
    title: 'Product',
    icon: 'layout-outline',
    children: [
      {
        title: 'Add Book',
        link: '/pages/forms/addProduct',
      },

      {
        title: 'Add Categories',
        link: '/pages/forms/addCategory',
      },
      {
        title: 'Add Author',
        link: '/pages/ui-features/grid',
      },
      {
        title: 'Add File',
        link: '/pages/forms/buttons',
      },

    ],
  },
  {
    title: 'Elibrary Table',
    icon: 'grid-outline',
    children: [
      {
        title: 'Produit Table',
        link: '/pages/tables/smart-table',
      },

      {
        title: 'Category Table',
        link: '/pages/tables/category-table',
      },
      {
        title: 'User Table',
        link: '/pages/tables/user-table',
      },

      {
        title: 'Order Table',
        link: '/pages/tables/order-table',
      },
    ],
  },







];
